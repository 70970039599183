<template>
  <v-container
    id="monthly-statement"
    fluid
    tag="section"
  >
    <v-row>
      <v-col cols="6">
        <v-autocomplete
          v-model="selectedAccount"
          class="mr-5 mt-0 pt-0"
          :disabled="isLoading"
          :items="accounts"
          color="primary"
          auto-select-first
          label="Select Account"
          item-text="name"
          item-value="account"
        />
      </v-col>
      <v-col cols="2">
        <date-picker
          :value="selectedMonth"
          type="MONTHLY"
          label="Choose month"
          @change-date="(d) => this.selectedMonth = d"
          :disabled="isLoading"
        />
      </v-col>
      <v-col
        cols="2"
        class="text-right"
      >
        <v-btn
          color="primary"
          :disabled="isLoading || !selectedMonth"
          @click="fetchMonthlyStatement()"
        >
          Get Monthly Statement
        </v-btn>
      </v-col>
      <v-col
        cols="2"
        class="text-left"
      >
        <v-btn
          color="secondary"
          :disabled="isLoading || !monthlyStatement.existMonthlyStatement"
          @click="downloadStatement()"
        >
          Download
          <v-icon
            dark
            right
          >
            mdi-download
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <table v-if="monthlyStatement.existMonthlyStatement" class="ms monthly-statement-table" style="width: 100%; border-collapse: collapse; ">
          <thead>
            <tr>
              <th style="width:25%; background: #CCCCCC">Name</th>
              <th style="width:25%; background: #CCCCCC">Value</th>
              <th style="width:50%; background: #CCCCCC">Comment</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <i>Starting HWM</i>
              </td>
              <td class="text-right">
                <i>{{formatNumber(monthlyStatement.startingHWM)}}</i>
              </td>
              <td></td>
            </tr>
            <tr>
              <td>
                <b>Starting Balance</b>
              </td>
              <td class="text-right">
                <b>{{formatNumber(monthlyStatement.startingBalance)}}</b>
              </td>
              <td></td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>
                Net
              </td>
              <td class="text-right">
                {{formatNumber(monthlyStatement.net)}}
              </td>
              <td></td>
            </tr>
            <tr>
              <td>
                Daily Interest
              </td>
              <td class="text-right">
                {{formatNumber(monthlyStatement.dailyInterest)}}
              </td>
              <td></td>
            </tr>
            <tr>
              <td>
                Short Margin Interest
              </td>
              <td class="text-right">
                {{formatNumber(monthlyStatement.smi)}}
              </td>
              <td></td>
            </tr>
            <tr>
              <td>
                <i>Dividends</i>
              </td>
              <td class="text-right">
                {{formatNumber(monthlyStatement.dividends)}}
              </td>
              <td></td>
            </tr>
            <tr>
              <td>
                Tax
              </td>
              <td class="text-right">
                {{formatNumber(monthlyStatement.adjTax)}}
              </td>
              <td></td>
            </tr>
            <tr>
              <td>
                Software
              </td>
              <td class="text-right">
                {{formatNumber(monthlyStatement.software)}}
              </td>
              <td></td>
            </tr>
            <tr>
              <td>
                Unrealized Delta
              </td>
              <td class="text-right">
                {{formatNumber(monthlyStatement.unrealizedDelta)}}
              </td>
              <td></td>
            </tr>
            <tr>
              <td>
                Admin Adjustment
              </td>
              <td class="text-right">
                {{formatNumber(monthlyStatement.adminAdjustmentValue)}}
              </td>
              <td>
                {{monthlyStatement.adminAdjustmentComment}}
              </td>
            </tr>
            <tr>
              <td style="background: #CCCCCC">
                <b>Monthly Result</b>
              </td>
              <td class="text-right" style="background: #CCCCCC">
                <b>{{formatNumber(monthlyStatement.monthlyResult)}}</b>
              </td>
              <td></td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td style="background: #CCCCCC">
                <b>Trader Profit</b>
              </td>
              <td class="text-right" style="background: #CCCCCC">
                <b>{{formatNumber(monthlyStatement.tradeProfit)}}</b>
              </td>
              <td></td>
            </tr>
            <tr>
              <td style="background: #CCCCCC">
                <b>Manager Bonus</b>
              </td>
              <td class="text-right" style="background: #CCCCCC">
                <b>{{formatNumber(monthlyStatement.managerBonus)}}</b>
              </td>
              <td></td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>
                <b>Debit/Bonus</b>
              </td>
              <td class="text-right">
                <b>{{formatNumber(monthlyStatement.withdrawal)}}</b>
              </td>
              <td></td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>
                Exchange Rate
              </td>
              <td class="text-right">
                {{formatNumber(monthlyStatement.exchangeRate, 5)}}
              </td>
              <td></td>
            </tr>
            <tr>
              <td>
                Adjustments/Fines
              </td>
              <td class="text-right">
                {{formatNumber(monthlyStatement.bonusFines)}}
              </td>
              <td>
                {{monthlyStatement.bonusFinesComment}}
              </td>
            </tr>
            <tr>
              <td>
                <b>Ending Balance</b>
              </td>
              <td class="text-right">
                <b>{{formatNumber(monthlyStatement.endingBalance)}}</b>
              </td>
              <td></td>
            </tr>
            <tr>
              <td>
                <b>Ending HWM</b>
              </td>
              <td class="text-right">
                <b>{{formatNumber(monthlyStatement.endingHWM)}}</b>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import MonthlyStatementService from './service/MonthlyStatementService'
import DatePicker from '@/components/DatePicker'

export default {
  name: 'MonthlyStatement',
  components: {
    DatePicker
  },
  data: () => ({
    accounts: [],
    selectedMonth: '',
    selectedAccount: '',
    monthlyStatement: {},
    isLoading: false,
    isMounted: false,
    ecnFeesHeaders: [
      { text: 'Route', value: 'route' },
      { text: 'Liquidity Indicator', value: 'liqIndicator' },
      { text: 'Value', value: 'value' }
    ],
    dialog: {
      show: false,
      title: '',
      message: ''
    }
  }),
  watch: {
  },
  beforeMount () {
    const today = new Date()
    today.setMonth(today.getMonth() - 1)
    this.selectedMonth = `${today.getFullYear()}-${today.getMonth() < 9 ? '0' + (today.getMonth() + 1) : today.getMonth() + 1}`
    this.fetchAccounts()
  },
  mounted () {
    this.isMounted = true
  },
  methods: {
    formatNumber (value, precision = 2) {
      let v = Number(value)
      if (v < 0) {
        v = v * -1
        return `(${Number(v.toFixed(precision)).toLocaleString('en-US')})`
      } else {
        return Number(v.toFixed(precision)).toLocaleString('en-US')
      }
    },
    async fetchAccounts () {
      const response = await MonthlyStatementService.fetchAccounts()
      if (response.status === 200) {
        this.accounts = response.data
      } else {
        this.accounts = []
      }

      this.selectedAccount = this.accounts.length > 0 ? this.accounts[0].account : ''
    },
    async fetchMonthlyStatement () {
      this.isLoading = true
      const response = await MonthlyStatementService.fetchReport(this.selectedAccount, this.selectedMonth + '-01')
      if (response.status === 200) {
        this.monthlyStatement = response.data
      } else {
        this.dialog.show = true
        this.dialog.title = 'Error'
        this.dialog.error = true
        this.dialog.message = 'Cannot load any data for given account and month!'
      }
      this.isLoading = false
    },
    async downloadStatement () {
      const reportName = this.selectedAccount
      const filename = `${reportName}.xls`
      const uri = `data:application/vnd.ms-excel;filename=${filename};base64,`
      const template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets>'
      const templateend = '</x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head>'
      const body = '<body>'
      const tablevar = '<table>{table'
      const tablevarend = '}</table>'
      const bodyend = '</body></html>'
      const worksheet = '<x:ExcelWorksheet><x:Name>'
      const worksheetend = '</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet>'
      const worksheetvar = '{worksheet'
      const worksheetvarend = '}'
      const base64 = function (s) { return window.btoa(unescape(encodeURIComponent(s))) }
      const format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p] }) }
      let wstemplate = ''
      let tabletemplate = ''

      const tables = document.getElementsByClassName('monthly-statement-table')

      for (var i = 0; i < tables.length; ++i) {
        wstemplate += worksheet + worksheetvar + i + worksheetvarend + worksheetend
        tabletemplate += tablevar + i + tablevarend
      }

      const allTemplate = template + wstemplate + templateend
      const allWorksheet = body + tabletemplate + bodyend
      const allOfIt = allTemplate + allWorksheet

      const ctx = {}
      for (let j = 0; j < tables.length; ++j) {
        ctx['worksheet' + j] = j
      }

      for (let k = 0; k < tables.length; ++k) {
        ctx['table' + k] = tables[k].innerHTML
      }

      // window.location.href = uri + base64(format(allOfIt, ctx))

      let link = document.createElement('a')
      link.href = uri + base64(format(allOfIt, ctx))
      link.download = filename
      link.target = '_blank'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      link = null
    },
    async downloadFile () {
      this.isLoading = true
      const response = null // await MonthlyStatementService.downloadMonthlyStatementFile() // TODO account and access id
      if (response.status === 200) {
        const blob = new Blob([response.data])
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = 'monthly-report.csv'
        link.click()
        URL.revokeObjectURL(link.href)
      } else {
        this.dialog.show = true
        this.dialog.title = 'Error'
        this.dialog.error = true
        this.dialog.message = 'Cannot download monthly report file!'
      }
      this.isLoading = false
    }
  }
}
</script>
<style>
table.ms th, table.ms td {
  border: 1px solid;
}

table.ms td {
  padding-left: 5px;
  padding-right: 5px;
}

</style>
